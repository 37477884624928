import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import {
  Container,
  makeStyles,
  Grid,
  Button,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Page from 'src/components/Page';
import Pager from 'src/utils/Pager'
import request from 'src/utils/request'
import { dateFormat } from 'src/utils/utils'
import appContext from 'src/AppContext'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff',
  },
  toolbar: {
    marginBottom: 12
  },
  
}));

const List = () => {
  const classes = useStyles();

  let {uid} = useParams()

  const [rowCount, setRowCount] = useState(0)
  const [list, setList] = useState([])
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(false)

  const context = useContext(appContext)

  useEffect(() => {
    request({
      url: '/ajax/score/list',
      method: 'get',
      data: {
        uid: (uid && uid != 0) ? uid : '',
        limit: 50,
        offset: offset,
      }
    }).then(res => {
      setRowCount(res.data.total)
      setList(res.data.list.map((item, index) => {
          item.mid = item.id
          item.id = index
          return item
      }))
      setLoading(false)
    })
  }, [offset])

  
  const columns = [
    {
      field: 'mid',
      headerName: 'ID',
      width: 80,
    }, 
    {
      field: 'amount',
      headerName: '分数变化',
      width: 180,
    }, 
    {
      field: 'desc',
      headerName: '描述',
      width: 180,
    }, 
  ]

  return <Page
    className={classes.root}
    title="积分项"
  >
    <Container maxWidth={false}>

      {list && <DataGrid components={{
            footer: Pager
          }}
        disableSelectionOnClick={true}
        className={classes.table}
        autoHeight
        rows={list}
        columns={columns}
        pagination
        paginationMode="server"
        pageSize={50} 
        rowCount={rowCount}
        onPageChange={(params) => {
          setOffset((params.page - 1) * 50)
        }}
        loading={loading}
      ></DataGrid>
      }
    </Container>

  </Page>
}

export default List