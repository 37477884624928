import React, { useContext,useEffect,useState } from 'react';
import {
  Container,
  makeStyles,
  Grid,
  Link,
  TextField,
  InputAdornment,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import appContext from 'src/AppContext'
import SearchIcon from '@material-ui/icons/Search';
import { DataGrid } from '@material-ui/data-grid';
import Pager from 'src/utils/Pager'
import request from 'src/utils/request'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff'
  },
  toolbar: {
    marginBottom: 12
  }
}));

const Example = () => {
  const classes = useStyles();
  const context = useContext(appContext)

  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    request({
      url: '/ajax/post/list',
      method: 'get',
      data: {
        limit: 20,
        offset: offset,
        kw: keyword
      }
    }).then(res => {
        setRowCount(res.data.total)
        setRows(res.data.list.map((item, index) => {
            item.aid = item.id
            item.id = index
            return item
        }))
        setLoading(false)
    })
  }, [offset, keyword])

  const updateState = (row, index) => {
    let newState = row.state === 1 ? 0 : 1
    // request({
    //   url: '/ajax/post/set_state',
    //   method: 'post',
    //   data: {
    //     id: row.aid,
    //     state: newState
    //   }
    // }).then(res => {
      let newRows = rows.concat()
      newRows[index].state = newState
      setRows(newRows)
      context.showToast('操作成功')
    // })
  }

  const remove = (row, index) => {
    // request({
    //   url: '/ajax/post/del',
    //   method: 'post',
    //   data: {
    //     id: row.aid
    //   }
    // }).then(res => {
      let newRows = rows.concat()
      newRows.splice(index, 1)
      setRows(newRows)
      context.showToast('删除成功')
    // })
  }


  return <Page
    className={classes.root}
    title="列表示例"
  >
    <Container maxWidth={false}>
        <Grid container className={classes.toolbar} justify="space-between">
        <Grid item xs={4} >
            <TextField
              label="搜索"
              fullWidth
              variant="outlined"
              color="primary"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              placeholder="输入关键字，按回车搜索"
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    setKeyword(e.target.value)
                }

                if (e.target.value === '') {
                    setKeyword('')
                }

                setOffset(0)
              }}
            ></TextField>
          </Grid>
          <Grid container item xs={4} justify="flex-end">
            <Grid item >
              <Button href="/app/example/add" target="_blank" variant="contained" color="primary">
                新增
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <DataGrid components={{
            footer: Pager
          }}
          className={classes.table}
          autoHeight
          disableSelectionOnClick={true}
          rows={rows} 
          columns={[
            {
                field: 'aid',
                headerName: 'ID',
                width: 120
            },
            {
                field: 'title',
                headerName: '标题',
                width: 400,
                sortable: false
            },
            {
                field: 'state',
                headerName: '状态',
                width: 120,
                align: 'center',
                sortable: false,
                valueGetter: (params) => {
                    switch(params.value) {
                    case 0: 
                        return '草稿'
                    case 1: 
                        return '线上'
                    default: 
                        return ''
                    }
                }
            },
            {
                field: 'action',
                headerName: '操作',
                width: 300,
                sortable: false,
                renderCell: (params) => {
                    return <Grid container direction="row" spacing={1}>
                        {params.row.state === 0 && <Grid item>
                            <Button size="small" color="primary" variant="contained" onClick={() => {
                                    updateState(params.row, params.rowIndex)
                                }}>上线</Button>
                        </Grid>}
                        {params.row.state === 1 && <Grid item>
                            <Button size="small" color="primary" variant="outlined" onClick={() => {
                                    updateState(params.row, params.rowIndex)
                                }}>下线</Button>
                        </Grid>}
                        <Grid item>
                            <Button href={`/app/example/edit/${params.row.aid}`} target="_blank"
                              size="small" color="primary" variant="contained">编辑</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" color="primary" variant="outlined" onClick={() => {
                                context.showConfirm({
                                    text: '确定要删除吗？',
                                    success: () => {
                                        remove(params.row, params.rowIndex)
                                        context.hideConfirm()
                                    }
                                })
                            }}>删除</Button>
                        </Grid>
                    </Grid>
                }
            }
          ]}
          pagination
          paginationMode="server"
          pageSize={20} 
          rowCount={rowCount}
          onPageChange={(params) => {
            setOffset((params.page - 1) * 20)
          }}
          loading={loading}
          ></DataGrid>
    </Container>
  </Page>
}

export default Example