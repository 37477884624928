import React from 'react';

const Logo = (props) => {
  return (
    <div>
      <img
        style={{height: 36, width: 36, border: '1px solid #ffffff'}}
        alt="Logo"
        src="/static/logo.png"
        {...props}
      />
    </div>
    
  );
};

export default Logo;
