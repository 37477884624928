import React, { useEffect, useState,useContext } from 'react'
import { 
    Grid,
    Tooltip,
    IconButton,
    TextField
} from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import appContext from 'src/AppContext'


const Pager = (props) => {
    let api = props.api.current
    const [currentPage, setCurrentPage] = useState()
    const [loading, setLoading] = useState(true)
    const context = useContext(appContext)

    useEffect(() => {
        if (props.pagination.page) {
            setCurrentPage(props.pagination.page)
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            })
        }
    }, [props.pagination.page])
    
    const setPageByInput = (e) => {
        let start = 1
        let end = props.pagination.pageCount
        if (e.key === 'Enter') {
            let value = e.target.value
            if (parseInt(value) !== NaN && parseInt(value) >= start && parseInt(value) <= end) {
                api.setPage(parseInt(value))
            } else {
                context.showToast(`请输入${start} - ${end}数字`)
            }
        }
    }

    return <div>
        <Grid container 
            direction="row" 
            justify="flex-end"
            alignItems="center"
            spacing={1}
            >
            <Grid item>
                {`${(props.pagination.page - 1)*props.pagination.pageSize + 1}-${props.pagination.page*props.pagination.pageSize} of ${props.pagination.rowCount}`}
                </Grid>
            <Grid item>
                {`共${props.pagination.pageCount}页`}
                </Grid>    
            <Grid item>
                <Tooltip title={props.pagination.page === 1 ? `已经是第一页了`:`上一页`}>
                    <IconButton size="small" onClick={() => {
                        if (props.pagination.page !== 1) {
                            api.setPage(props.pagination.page - 1)
                        }
                    }} aria-label="before">
                        <NavigateBeforeIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                {!loading && <TextField 
                    variant="outlined" 
                    style={{width: 56}} 
                    defaultValue={currentPage}
                    onKeyUp={setPageByInput}
                />}
            </Grid>
            <Grid item>
            <Tooltip title={props.pagination.page === props.pagination.pageCount ? `已经是最后一页了` : `下一页`}>
                <IconButton size="small" onClick={() => {
                    if (props.pagination.page !== props.pagination.pageCount) {
                        api.setPage(props.pagination.page + 1)
                    }
                }} aria-label="next">
                    <NavigateNextIcon />
                </IconButton>
            </Tooltip>
            </Grid>
        </Grid>
    </div>
    
}

export default Pager