import React, { useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Grid,
  Button,
  Paper,
  FormHelperText,
  IconButton
} from '@material-ui/core'
import Page from 'src/components/Page'
import DeleteIcon from '@material-ui/icons/Delete';
import * as qiniu from 'qiniu-js'
import request from 'src/utils/request'



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff'
  },
  toolbar: {
    marginBottom: 12
  },
  select: {
    width: 100,
    '&::before' : {
      display: 'none'
    }
  },
  dialog: {
    width: 400
  },
}));

const Example = () => {
  const classes = useStyles();
  
  const fileInput = React.createRef()
  const [list, setList] = useState([])
  const [token, setToken] = useState()

  useEffect(() => {
    request({
      url: '/ajax/qiniu/token',
      method: 'get',
      data: {
        prefix: 'other/'
      }
    }).then(res => {
      if (res.data) {
        setToken(res.data.token)
      }
    }, err => {
      
    })
  }, [])

   const addFiles = async (files) => {
    let newList = [] 
    for (let i = 0; i<files.length; i++) {
        let file = files[i]
        let base64 = await convertBase64(file)
        newList.push({
            file,
            base64
        })
    } 
    let copy = list.concat(newList)
    setList(copy)
  }

  const upload = async (files) => {
      
  }

  const getQiniuKey = async (file) => {
    new Promise((resolve, reject) => {
        qiniu.upload(
            file, 
            null, 
            token
        ).subscribe({
            error(err) {
                reject(err)
            },
            complete(res) {
                if (res.key) {
                    resolve(res.key)
                } else {
                    reject(res)
                }
            }
        })
    })
  }

   const convertBase64 = async (file) => {
      return new Promise(resolve => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function (evt) {
          let base64 = evt.target.result
          resolve(base64)
        }
        console.log(file)
      })
    }

    const ImageItem = ({item, index}) => (
        <Paper style={{width: 200, height: 240, margin: 5, overflow: 'hidden'}}>
             <Grid direction="column">
                <Grid item>
                    <a target="_blank" href={item.base64 || `//pic.designpassport.net/${item.qiniu}?imageView2/1/w/300`}>
                        <img style={{width: 200}} src={item.base64 || `//pic.designpassport.net/${item.qiniu}?imageView2/1/w/300`} />
                    </a>
                </Grid>
                <Grid item container direction="row" alignItems="center" justify="space-between">
                    <FormHelperText>上传中..</FormHelperText>
                    <IconButton size="small" onClick={() => {
                    }} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
       
    )


  return <Page
    className={classes.root}
    title="多图上传"
  >
    <Container maxWidth={false}>
      <Grid container justify="flex-start" direction="column" spacing={2}>
        <Grid item>
          <h2>多图上传</h2>
        </Grid>
        <Grid item>
            <Button color="primary" variant="outlined" onClick={() => {
                fileInput.current.click()
            }}>选择图片</Button>
            <input 
                ref={fileInput} 
                type="file" 
                accept={`image/*`}
                multiple
                onChange={async (e) => {
                    await addFiles(e.target.files)
                }}
                hidden ></input>
        </Grid>
        <Grid item container direction="row">
            {list.map((item, index) => (
                <ImageItem item={item} index={index} /> 
            ))}
        </Grid>
      </Grid>
    </Container>
  </Page>
}

export default Example