import React, { useContext,useEffect,useState } from 'react';
import { useParams } from "react-router-dom"
import {
  Container,
  makeStyles,
  Grid,
  FormControl,
  TextField,
  Select,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import appContext from 'src/AppContext'
import { DataGrid } from '@material-ui/data-grid';
import Pager from 'src/utils/Pager'
import request from 'src/utils/request'
import { dateFormat } from 'src/utils/utils'
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff'
  },
  toolbar: {
    marginBottom: 12
  },
  select: {
    '&::before' : {
      display: 'none'
    }
  },
  tableCellTrucate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));

const List = () => {
  const classes = useStyles();
  const context = useContext(appContext)
  let { oid, uid, aid } = useParams()

  let stateObj = {
    // 'new': '新建',
    'cancelled': '已取消',
    // 'paid': '已付款',
    'confirmed': '已支付',
    'finished': '交易完成',
    'closed': '交易关闭'
  }

  // let refundStateObj = {
  //   '0': '无',
  //   '1': '待退款',
  //   '2': '退款处理中',
  //   '3': '已退款',
  //   '4': '退款失败'
  // }

  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(false)
  const [searchOid, setSearchOid] = useState(oid || 0)
  const [searchUid, setSearchUid] = useState(uid || 0)
  const [searchAid, setSearchAid] = useState(aid || 0)
  const [state, setState] = useState('')
  // const [refundState, setRefundState] = useState('')



  useEffect(() => {
    let params = []
    request({
      url: `/ajax/order/list`,
      method: 'get',
      data: {
        limit: 50,
        offset: offset,
        kw: '',
        id: (oid && oid != 0) ? oid : '',
        uid: (uid && uid != 0) ? uid : '',
        activity_id: (aid && aid != 0) ? aid : '',
        state: state,
        // refund_state: refundState
      }
    }).then(res => {
        setRowCount(res.data.total)
        setRows(res.data.list.map((item, index) => {
            item.mid = item.id
            item.id = index
            item.price = Math.floor((item.price/100) * 100) / 100
            item.unit_price = Math.floor((item.unit_price/100) * 100) / 100
            return item
        }))
        setLoading(false)
    })
  }, [offset,state])


  return <Page
    className={classes.root}
    title="订单列表"
  >
    <Container maxWidth={false}>
      <Grid container className={classes.toolbar} spacing={1}>
        <Grid item xs={3} >
            <TextField
              label="订单号"
              fullWidth
              variant="outlined"
              color="primary"
              defaultValue={oid !=0 ? oid : ''}
              onChange={(e) => {
                setSearchOid(e.target.value || 0)
              }}
            ></TextField>
          </Grid>
          <Grid container item xs={2}>
            <TextField
                label="活动id"
                fullWidth
                variant="outlined"
                color="primary"
                defaultValue={aid !=0 ? aid: ''}
                onChange={(e) => {
                  setSearchAid(e.target.value || 0)
                }}
              ></TextField>
          </Grid>
          <Grid container item xs={2}>
            <TextField
                label="用户id"
                fullWidth
                variant="outlined"
                color="primary"
                defaultValue={uid !=0 ? uid: ''}
                onChange={(e) => {
                  setSearchUid(e.target.value || 0)
                }}
              ></TextField>
          </Grid>
          <Grid container item xs={2}>
            <Button color="primary" variant="outlined" fullWidth onClick={() => {
              if (searchOid || searchAid || searchUid) {
                window.location.href = `/app/order/${searchOid}/${searchAid}/${searchUid}`
              }
            }}>查询</Button>
          </Grid>
          <Grid container item xs={2}>
            <Button color="default" variant="outlined" fullWidth onClick={() => {
                window.location.href = `/app/order`
            }}>重置</Button>
          </Grid>
        </Grid>
        <DataGrid components={{
            footer: Pager
          }}
          className={classes.table}
          autoHeight
          disableSelectionOnClick={true}
          rows={rows} 
          columns={[
            {
                field: 'mid',
                headerName: '订单id',
                width: 200
            },
            {
              field: 'activity_id',
              headerName: '活动id',
              width: 90
            },
            {
                field: 'title',
                headerName: '订单标题',
                width: 220,
                sortable: false,
                renderCell: (params) =>  (
                  <Tooltip title={params.value} placement="bottom-start">
                   <span className={classes.tableCellTrucate}>{params.value}</span>
                   </Tooltip>
                ),
            },
            {
                field: 'ctime',
                headerName: '下单时间',
                width: 170,
                sortable: false,
                valueGetter: (params) => {
                    return dateFormat(new Date(params.value * 1000)) 
                }
            },
            {
              field: 'state_str',
              headerName: '订单状态',
              width: 120,
              sortable: false,
              renderHeader: () => {
                return <FormControl>
                <Select
                  className={classes.select}
                  native
                  color="primary"
                  variant="standard"
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value)
                    setOffset(0)
                  }}
                  inputProps={{
                    style: {
                      height: 40,
                      fontSize: '0.875em',
                      fontWeight: 500
                    }
                  }}
                >
                  <option value="">全部状态</option>
                  {Object.keys(stateObj).map(key => {
                    return <option value={key}>{stateObj[key]}</option>
                  })}
                </Select>
              </FormControl>
              }
            },
            {
              field: 'use_state',
              headerName: '核销状态',
              width: 120,
              align: 'center',
              sortable: false,
              valueGetter: (params) => {
                  switch(params.value) {
                  case 1: 
                      return '可用'
                  case 2: 
                      return '已核销'
                  case 3: 
                      return '取消'
                  case 4: 
                      return '过期'
                  default: 
                      return ''
                  }
              }
            },
            {
              field: 'price',
              headerName: '总价(元)',
              width: 100,
              sortable: false
            },
            // {
            //   field: 'unit_price',
            //   headerName: '单价(元)',
            //   width: 100,
            //   sortable: false
            // },
            {
                field: 'num',
                headerName: '购买数',
                width: 90,
                sortable: false
            },
            {
              field: 'uid',
              headerName: '用户id',
              width: 90,
              sortable: false
            },
            {
                field: 'username',
                headerName: '用户',
                width: 120,
                sortable: false
            },
            {
              field: 'contact_name',
              headerName: '姓名',
              width: 120,
              sortable: false
            },
            {
              field: 'contact_mobile',
              headerName: '手机号',
              width: 140,
              sortable: false
            },
            
            // {
            //   field: 'refund_state_str',
            //   headerName: '退款状态',
            //   width: 140,
            //   sortable: false,
            //   renderHeader: () => {
            //     return <FormControl>
            //     <Select
            //       className={classes.select}
            //       style={{width: 110}}
            //       native
            //       color="primary"
            //       variant="standard"
            //       value={refundState}
            //       onChange={(e) => {
            //         setRefundState(e.target.value)
            //         setOffset(0)
            //       }}
            //       inputProps={{
            //         style: {
            //           height: 40,
            //           fontSize: '0.875em',
            //           fontWeight: 500
            //         }
            //       }}
            //     >
            //       <option value="">全部退款状态</option>
            //       {Object.keys(refundStateObj).map(key => {
            //         return <option value={key}>{refundStateObj[key]}</option>
            //       })}
            //     </Select>
            //   </FormControl>
            //   }
            // },
            // {
            //   field: 'action',
            //   headerName: '操作',
            //   width: 200,
            //   sortable: false,
            //   renderCell: (params) => {
            //     return <Grid container direction="row" spacing={1}>
            //         {params.row.can_refund === 1 && <Grid item>
            //             <Button size="small" color="default" variant="outlined" onClick={() => {
            //               context.showConfirm({
            //                 text: '确定要申请退款吗？',
            //                 success: () => {
            //                   refund(params)
            //                   context.hideConfirm()

            //                 }
            //               })
            //             }}>申请退款</Button>
            //         </Grid>}
                    
            //     </Grid>
            //   }
            // },
          ]}
          pagination
          paginationMode="server"
          pageSize={50} 
          rowCount={rowCount}
          onPageChange={(params) => {
            setOffset((params.page - 1) * 50)
          }}
          loading={loading}
          ></DataGrid>
    </Container>
  </Page>
}

export default List