import React, { } from 'react';
import {
  Container,
  makeStyles,
  Grid,
  Link
} from '@material-ui/core';
import Page from 'src/components/Page';
import appContext from 'src/AppContext'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff'
  },
  toolbar: {
    marginBottom: 12
  },
  select: {
    width: 100,
    '&::before' : {
      display: 'none'
    }
  },
  dialog: {
    width: 400
  },
}));

const Example = () => {
  const classes = useStyles();

  return <Page
    className={classes.root}
    title="我是一个示例页面"
  >
    <Container maxWidth={false}>
      <Grid container justify="flex-start" direction="column" spacing={2}>
        <Grid item>
          <h2>我是一个示例页面</h2>
        </Grid>
        <Grid item>
          <Link href="example/table" target="_blank">列表页</Link>
        </Grid>
        <Grid item>
          <Link href="example/add" target="_blank">新增/编辑页</Link>
        </Grid>
        <Grid item>
          <Link href="example/quill" target="_blank">编辑器</Link>
        </Grid>
        <Grid item>
          <Link href="example/tabs" target="_blank">Tabs</Link>
        </Grid>
        <Grid item>
          <Link href="example/upload" target="_blank">上传图片</Link>
        </Grid>
      </Grid>
    </Container>
  </Page>
}

export default Example