import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Page from 'src/components/Page';
import Pager from 'src/utils/Pager'
import request from 'src/utils/request'
import { dateFormat } from 'src/utils/utils'
import appContext from 'src/AppContext'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff',
  },
  toolbar: {
    marginBottom: 12
  },
  
}));

const Ad = () => {
  const classes = useStyles();

  const [rowCount, setRowCount] = useState(0)
  const [list, setList] = useState([])
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(false)

  const context = useContext(appContext)

  useEffect(() => {
    request({
      url: '/ajax/banner/list',
      method: 'get',
      data: {
        limit: 20,
        offset: offset,
      }
    }).then(res => {
      setRowCount(res.data.total)
      setList(res.data.list.map((item, index) => {
          item.mid = item.id
          item.id = index
          return item
      }))
      setLoading(false)
    })
  }, [offset])

  const setState = (item, index) => {
    let newState = item.state === 1 ? 0 : 1
    request({
      url: '/ajax/banner/set_state',
      method: 'post',
      data: {
        id: item.mid,
        state: newState
      }
    }).then(res => {
      let newlist = [...list]
      newlist[index].state = newState
      setList(newlist)
      context.showToast('操作成功')
    })
  }

  const remove = (row, index) => {
    request({
      url: '/ajax/banner/del',
      method: 'post',
      data: {
        id: row.mid
      }
    }).then(() => {
      let newRows = list.filter(function(value, index, arr){ 
          return row.mid != value.mid;
      });

      setList(newRows)
      context.showToast('删除成功')
    })
  }

  const updateSort = (row, value, index) => {
    request({
      url: '/ajax/banner/sort',
      method: 'post',
      data: {
        id: row.mid,
        sort: value
      }
    }).then(res => {
      let newRows = list.concat()
      newRows[index].sort = value
      setList(newRows)
    })
  }

  const columns = [
    {
      field: 'mid',
      headerName: 'ID',
      width: 80,
    }, 
    {
      field: 'note',
      headerName: '说明',
      width: 180,
    }, 
    // {
    //   field: 'title',
    //   headerName: '广告名',
    //   width: 160,
    // }, 
    {
      field: 'cover',
      headerName: '图片',
      width: 80,
      renderCell: (params) => {
        if(params.row.cover_url === ''){
          return '-';
        }
        return <img alt="封图" style={{width: 60}} src={`${params.row.cover_url}?imageView2/1/w/${60}`} />
      }
    }, 
    // {
    //   field: 'begintime',
    //   headerName: '开始时间',
    //   type: 'dateTime',
    //   width: 180,
    //   valueGetter: (params) => {
    //     return params.row.begin_time ? dateFormat(new Date(params.row.begin_time * 1000)): '-'
    //   }
    // }, {
    //   field: 'endTime',
    //   headerName: '结束时间',
    //   type: 'dateTime',
    //   width: 180,
    //   valueGetter: (params) => {
    //     return params.row.end_time ? dateFormat(new Date(params.row.end_time * 1000)): '-'
    //   }
    // }, 
    {
      field: 'sort',
      headerName: '排序',
      width: 120,
      sortable: false,
      renderCell: (params) => {
        return  <>
        {!loading && <TextField
          className={classes.numberTextField}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: 0,
            max: 999
          }}
          variant="outlined"
          defaultValue={params.row.sort}
          onKeyUp={(e) => {
            if (parseInt(e.target.value) < 0) {
              e.target.value = 0
            }

            if (parseInt(e.target.value) > 999) {
              e.target.value = 999
            }
          }}
          onChange={(e) => {
            let sort = parseInt(e.target.value)
            if (sort >= 0 && sort <= 999) {
              updateSort(params.row, e.target.value, params.rowIndex)
            }
          }}
        />}
        </>
      }
    },
    {
      field: 'state',
      headerName: '状态',
      width: 70,
      valueGetter: (params) => {
        return params.row.state === 1 ? '启用': '禁用'
      }
    }, {
      field: 'action',
      headerName: '操作',
      width: 240,
      renderCell: (params) => {
        return <Grid container direction="row" spacing={1}>
              {params.row.state === 0 && 
                <Grid item>
                  <Button size="small" 
                color="primary" 
                variant="contained"
                onClick={() => {
                  setState(params.row, params.rowIndex)
                }}>启用</Button>
              </Grid>}
              {params.row.state === 1 && 
                <Grid item>
                  <Button 
                    size="small" 
                    color="primary" 
                    variant="outlined"
                    onClick={() => {
                      setState(params.row, params.rowIndex)
                    }}
                    >
                  禁用
                </Button>
              </Grid>}
              <Grid item>
                <Button 
                  size="small" 
                  variant="contained" 
                  color="primary"
                  href={`/app/banner/edit/${params.row.mid}`}
                  >编辑</Button>
              </Grid>
              <Grid item>
                  <Button size="small" color="default" variant="outlined" onClick={() => {
                      context.showConfirm({
                          text: '确定要删除吗？',
                          success: () => {
                              remove(params.row, params.rowIndex)
                              context.hideConfirm()
                          }
                      })
                  }}>删除</Button>
              </Grid>
            </Grid>
      }
    }
  ]

  return <Page
    className={classes.root}
    title="轮播图"
  >
    <Container maxWidth={false}>
      <Grid container className={classes.toolbar} spacing={1}>
        <Grid item container xs={2} >
          <Button variant="contained" color="primary" href="/app/banner/add" >添加轮播图</Button>
        </Grid>
        <Grid item container xs={2}>
          <Button variant="contained" color="default" href="/app/recentact" >配置近期活动</Button>
        </Grid>
        <Grid item container xs={2}>
          <Button variant="contained" color="default" href="/app/offiaccount" >配置公众号</Button>
        </Grid>
      </Grid>

      {list && <DataGrid components={{
            footer: Pager
          }}
        disableSelectionOnClick={true}
        className={classes.table}
        autoHeight
        rows={list}
        columns={columns}
        pagination
        paginationMode="server"
        pageSize={20} 
        rowCount={rowCount}
        onPageChange={(params) => {
          setOffset((params.page - 1) * 20)
        }}
        loading={loading}
      ></DataGrid>
      }
    </Container>

  </Page>
}

export default Ad