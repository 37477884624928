import React from 'react'
import { Navigate } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import MainLayout from 'src/layouts/MainLayout'
import DashboardView from 'src/views/dashboard'
import LoginView from 'src/views/auth/LoginView'
import NotFoundView from 'src/views/errors/NotFoundView'

import ExampleView from 'src/views/example'
import ExampleTableView from 'src/views/example/table'
import ExampleEditView from 'src/views/example/edit'
import ExampleQuillView from 'src/views/example/quill'
import ExampleTabsView from 'src/views/example/tabs'
import ExampleUploadView from 'src/views/example/upload'

import FestivalView from 'src/views/festival'
import FestivalEditView from 'src/views/festival/edit'

import BoardView from 'src/views/board'
import BoardEditView from 'src/views/board/edit'

import ExhiView from 'src/views/exhibition'
import ExhiEditView from 'src/views/exhibition/edit'

import ActView from 'src/views/activity'
import ActEditView from 'src/views/activity/edit'

import ProductView from 'src/views/product'
import ProductEditView from 'src/views/product/edit'

import AdView from 'src/views/ad';
import AdEditView from 'src/views/ad/edit';

import RecentactView from 'src/views/recentactivity';
import OffiaccountView from 'src/views/offiaccount'

import ChangepwdView from 'src/views/admin/changepwd';

import OrderView from 'src/views/order'
import OrderCheckView from 'src/views/order/check'

import UserView from 'src/views/user'

import ScoreitemView from 'src/views/scoreitem'
import ScoreitemEditView from 'src/views/scoreitem/edit'

import ScoreView from 'src/views/score'

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <DashboardView /> },

      { path: 'festival', element: <FestivalView /> },
      { path: 'festival/add', element: <FestivalEditView /> },
      { path: 'festival/edit/:id', element: <FestivalEditView /> },

      { path: 'board', element: <BoardView /> },
      { path: 'board/:fid', element: <BoardView /> },
      { path: 'board/add/:fid', element: <BoardEditView /> },
      { path: 'board/edit/:id', element: <BoardEditView /> },

      { path: 'exhibition', element: <ExhiView /> },
      { path: 'exhibition/:fid', element: <ExhiView /> },
      { path: 'exhibition/add/:fid', element: <ExhiEditView /> },
      { path: 'exhibition/edit/:id', element: <ExhiEditView /> },

      { path: 'activity', element: <ActView /> },
      { path: 'activity/:fid', element: <ActView /> },
      { path: 'activity/add/:fid', element: <ActEditView /> },
      { path: 'activity/edit/:id', element: <ActEditView /> },

      { path: 'product/:aid', element: <ProductView /> },
      { path: 'product/add/:aid', element: <ProductEditView /> },
      { path: 'product/edit/:id', element: <ProductEditView /> },

      { path: 'banner', element: <AdView /> },
      { path: 'banner/add', element: <AdEditView /> },
      { path: 'banner/edit/:id', element: <AdEditView /> },

      { path: 'recentact', element: <RecentactView /> },

      { path: 'offiaccount', element: <OffiaccountView /> },

      { path: 'changepwd', element: <ChangepwdView /> },

      { path: 'order', element: <OrderView /> },
      { path: 'order/:oid/:aid/:uid', element: <OrderView /> },
      { path: 'checkorder', element: <OrderCheckView /> },

      { path: 'user', element: <UserView /> },

      { path: 'scoreitem', element: <ScoreitemView /> },
      { path: 'scoreitem/add', element: <ScoreitemEditView /> },
      { path: 'scoreitem/edit/:id', element: <ScoreitemEditView /> },

      { path: 'score/:uid', element: <ScoreView /> },
      
      { path: 'example', element: <ExampleView /> },
      { path: 'example/table', element: <ExampleTableView /> },
      { path: 'example/add', element: <ExampleEditView /> },
      { path: 'example/edit/:id', element: <ExampleEditView /> },
      { path: 'example/quill', element: <ExampleQuillView /> },
      { path: 'example/tabs', element: <ExampleTabsView /> },
      { path: 'example/upload', element: <ExampleUploadView /> },

      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ]
  }
];

export default routes;
