import React, { Component } from "react"
import Quill from 'quill'
import quillTableUI from 'quill-table-ui'
import request from 'src/utils/request'
import * as qiniu from 'qiniu-js'
import md5 from 'blueimp-md5'

import 'quill/dist/quill.snow.css'
import 'quill-table-ui/dist/index.css'



const base64ToFile = (base64, fileName) => {
    let arr = base64.split(','), type = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type});
}

const getHTMLFromQuill = (content) => {
    let tempCont = document.createElement("div")
    let quill = new Quill(tempCont)
    quill.setContents(content)
    return quill.root.innerHTML
}

let self
let imageRecorder = {}

class BriefEditor extends Component {
    editor
    editorId 
    toolbar
    editorWidth
    editorHeight
    qiniuToken
    qiniuPrefix

    init () {
        self = this
        this.editorId = this.props.editorId || 'editor'
        this.toolbar = this.props.toolbar || ['bold', 'image']

        this.editorWidth = this.props.width || '100%'
        this.editorHeight = this.props.height || 320
        this.qiniuPrefix = this.props.qiniuPrefix || 'public/'
    }

    getQiniuToken () {
        request({
            url: '/ajax/qiniu/token',
            method: 'get',
            data: {
                prefix: this.qiniuPrefix
            }
        }).then(res => {
            this.qiniuToken = res.data.token
        }, err => {
        
        })
    }

    getBase64Key (base64) {
        return md5(base64)
    }

    uploadImage (delta, callback) {
        delta.ops.forEach((item) => {
            if (item.insert && item.insert.image && item.insert.image.search(/base64/) !== -1) {
                let base64 = item.insert.image
                let base64Key = md5(base64)
                if (!imageRecorder[base64Key]) {
                    let file = base64ToFile(base64, `file${new Date().getTime()}`)
                    if (file) {
                        ((f, key) => {
                            qiniu.upload(
                                f, 
                                null, 
                                self.qiniuToken
                            ).subscribe({
                                error(err) {
                                    
                                },
                                complete(res) {
                                    if (res.key) {
                                       imageRecorder[key] = res.key
                                        if (callback) {
                                            callback()
                                        }
                                       
                                    }
                                }
                            })
                        })(file, base64Key)
                    }
                }
                
            }
        })
        
    }

    transformContent () {
        let contents = this.editor.getContents()
        contents.ops.forEach((item, index) => {
            if (item.insert && item.insert.image && item.insert.image.search(/base64/) !== -1) {
                let base64 = item.insert.image
                let base64Key = md5(base64)
                item.insert.image = `https://pic.designpassport.net/${imageRecorder[base64Key]}`
            }
        })
        return contents
    }

    componentDidMount() {

        Quill.register({
            'modules/tableUI': quillTableUI
        }, true)

        this.editor = new Quill(`#${this.editorId}`, {
            theme: 'snow',
            modules: { 
                toolbar: this.toolbar,
                table: true,
                tableUI: true
            }
        })

        if (this.toolbar.indexOf('table') !== -1) {
            this.moduleTable = this.editor.getModule('table')
            this.moduleToolbar = this.editor.getModule('toolbar')
            this.moduleToolbar.addHandler('table', (e) => {
                this.moduleTable.insertTable(1, 2)
            })
        }

        if (this.props.content) {
            this.editor.setContents(this.props.content)
        } else if (this.props.html) {
            this.editor.root.innerHTML = this.props.html
        }

        this.editor.on('text-change', (delta, oldDelta, source) => {
            if (this.props.onChange) {
                let hasImage = delta.ops.find(item => {
                    return item.insert && item.insert.image && item.insert.image.search(/base64/) !== -1
                })

                if (hasImage) {
                    this.uploadImage(delta, () => {
                        let content = this.transformContent()
                        this.props.onChange(content, getHTMLFromQuill(content))
                    })
                } else {
                    let content = this.transformContent()
                    this.props.onChange(content, getHTMLFromQuill(content))
                }
                
            }
        })

    }

    render() {
        this.init()
        if (!this.qiniuToken) {
            this.getQiniuToken()
        }
        
        return <>
        {this.editorId && <div>
            <style>
                {`  
                    #${this.editorId} {
                        overflow-y: auto;
                    }

                    #${this.editorId} img {
                        width: 100%;
                    }

                    #editor .ql-tooltip {
                        left: 0 !important;
                        right: 0 !important;
                    }

                    #editor table td {
                        width: 30%
                    }
                `}
            </style>
            {/* <div id={`${this.editorId}Toolbar`}>
                {this.toolbar.map(item => (
                    <>
                        {typeof item === 'string' && <button className={`ql-${item}`}></button>}
                        {typeof item === 'object' && <button className={`ql-${item.key}`} value={item.value}></button>}
                    </>
                ))}
            </div> */}
            <div id={this.editorId}
                style={{width: this.editorWidth, height: this.editorHeight}}></div>
        </div>}
        </>
    }
}


export default BriefEditor
export { getHTMLFromQuill }