import 'react-perfect-scrollbar/dist/css/styles.css';
import React, {useState} from 'react';
import { useRoutes } from 'react-router-dom';
import { 
  ThemeProvider,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import AppContext from 'src/AppContext'


const App = () => {
  const routing = useRoutes(routes);
  const [toast, setToast] = useState(false)
  const [toastMsg, setToastMsg] = useState('success')
  const [confirm, setConfirm] = useState(false)

  const defaultConfirmInfo = {
    text: '',
    success: () => {
      hideConfirm()
    },
    failed: () => {
      hideConfirm()
    }
  }
  const [confirmInfo, setConfirmInfo] = useState(defaultConfirmInfo)

  const showToast = (text) => {
    setToast(true)
    setToastMsg(text || 'success')
  }

  const showConfirm = ({
    text,
    success,
    failed
  }) => {
    setConfirm(true)
    setConfirmInfo({
      text: text,
      success: success || defaultConfirmInfo.success,
      failed: failed || defaultConfirmInfo.failed
    })
  }

  const hideConfirm = () => {
    setConfirm(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppContext.Provider value={{
        showToast,
        showConfirm,
        hideConfirm
      }}>
        {routing}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
          autoHideDuration={1000}
          open={toast}
          onClose={() => {
            setToast(false)
          }}
          message={toastMsg}
        >
        </Snackbar>
        <Dialog
          open={confirm}
          onClose={hideConfirm}
        >
          <DialogTitle>提示</DialogTitle>
          <DialogContent style={{width: 400}}>
            <DialogContentText>{confirmInfo.text}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={confirmInfo.failed} color="default">
              否
            </Button>
            <Button onClick={confirmInfo.success} color="primary" autoFocus variant="contained">
              是
            </Button>
          </DialogActions>
        </Dialog>
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default App;
