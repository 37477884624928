import React, { useState, useEffect, useContext } from 'react'
import { 
    makeStyles,
    Container,
    Grid,
    TextField,
    Button
} from "@material-ui/core"
import Page from 'src/components/Page'
import request from 'src/utils/request'
import appContext from 'src/AppContext'

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      padding: theme.spacing(3)
    },
    toolbar: {
        marginBottom: 12
    },
  }));

const Settings = () => {
    const classes = useStyles()
    const [val, setVal] = useState('')
    const context = useContext(appContext)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        request({
            url: '/ajax/conf/info/recent_activity',
            method: 'get',
        }).then(res => {
            // console.log(res)
            if (res && res.data) {
                setVal(res.data.value)
            }
            setLoading(false)
        }, err => {
            context.showToast(err)
            setLoading(false)
        })
    }, [])

    return <Page
        className={classes.root}
        title="设置近期活动"
    >
        <Container maxWidth={false}>
            {!loading && <Grid direction="column" container spacing={2}>
                <Grid item>
                    <h4>近期活动</h4>
                    <p>请填写活动id。多个ID用英文逗号分隔。例如 15,26,32</p>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                    fullWidth
                    value={val}
                    onChange={(e)=>{
                        // console.log(e.target.value)
                        setVal(e.target.value)
                    }}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => {
                        request({
                            url: '/ajax/conf/save/recent_activity',
                            method: 'post',
                            data: {
                                value: val
                            }
                        }).then(res => {
                            context.showToast('保存成功')
                        }, err => {
                            context.showToast(err)
                        })
                    }}>保存</Button>
                </Grid>
            </Grid>}
        </Container>
    </Page>
}

export default Settings