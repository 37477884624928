import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Grid,
  Button,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Page from 'src/components/Page';
import Pager from 'src/utils/Pager'
import request from 'src/utils/request'
import { dateFormat } from 'src/utils/utils'
import appContext from 'src/AppContext'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff',
  },
  toolbar: {
    marginBottom: 12
  },
  
}));

const List = () => {
  const classes = useStyles();

  const [rowCount, setRowCount] = useState(0)
  const [list, setList] = useState([])
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(false)

  const context = useContext(appContext)

  useEffect(() => {
    request({
      url: '/ajax/scoreitem/list',
      method: 'get',
      data: {
        limit: 50,
        offset: offset,
      }
    }).then(res => {
      setRowCount(res.data.total)
      setList(res.data.list.map((item, index) => {
          item.mid = item.id
          item.id = index
          return item
      }))
      setLoading(false)
    })
  }, [offset])

  const updateState = (row, index) => {
    let newState = row.state === 1 ? 0 : 1
    request({
      url: '/ajax/scoreitem/set_state',
      method: 'post',
      data: {
        id: row.mid,
        state: newState
      }
    }).then(res => {
      let newRows = list.concat()
      newRows[index].state = newState
      setList(newRows)
      context.showToast('操作成功')
    })
  }

  const remove = (row, index) => {
    request({
      url: '/ajax/scoreitem/del',
      method: 'post',
      data: {
        id: row.mid
      }
    }).then(() => {
      let newRows = list.filter(function(value, index, arr){ 
          return row.mid != value.mid;
      });

      setList(newRows)
      context.showToast('删除成功')
    })
  }

  const columns = [
    {
      field: 'mid',
      headerName: 'ID',
      width: 80,
    }, 
    {
      field: 'name',
      headerName: '名称',
      width: 180,
    }, 
    {
      field: 'type',
      headerName: '类型',
      width: 100,
      valueGetter: (params) => {
        switch(params.value) {
        case "1": 
            return '增加'
        case "2": 
            return '兑换'
        default: 
            return ''
        }
      }
    }, 
    {
      field: 'amount',
      headerName: '分数',
      width: 120,
    }, 
    {
      field: 'state',
      headerName: '状态',
      width: 100,
      align: 'center',
      sortable: false,
      valueGetter: (params) => {
          switch(params.value) {
          case 0: 
              return '禁用'
          case 1: 
              return '启用'
          default: 
              return ''
          }
      }
    },
    {
      field: 'ctime',
      headerName: '创建时间',
      width: 150,
      sortable: false,
      valueGetter: (params) => {
          return dateFormat(new Date(params.value * 1000), "YYYY-mm-dd HH:MM") 
      }
    },
    {
      field: 'action',
      headerName: '操作',
      width: 320,
      renderCell: (params) => {
        return <Grid container direction="row" spacing={1}>
              <Grid item>
                <Button 
                  size="small" 
                  variant="contained" 
                  color="primary"
                  href={`/app/scoreitem/edit/${params.row.mid}`}
                  >编辑</Button>
              </Grid>
              {params.row.state === 0 && <Grid item>
                  <Button size="small" color="primary" variant="contained" onClick={() => {
                          updateState(params.row, params.rowIndex)
                      }}>上线</Button>
              </Grid>}
              {params.row.state === 1 && <Grid item>
                  <Button size="small" variant="outlined" onClick={() => {
                          updateState(params.row, params.rowIndex)
                      }}>下线</Button>
              </Grid>}
              <Grid item>
                  <Button size="small" variant="outlined" onClick={() => {
                    window.open(`https://manage.designpassport.net/ajax/scoreitem/qrcode?id=${params.row.mid}&type=${params.row.type}`)
                  }}>二维码</Button>
              </Grid>
              <Grid item>
                  <Button size="small" color="default" variant="outlined" onClick={() => {
                      context.showConfirm({
                          text: '确定要删除吗？',
                          success: () => {
                              remove(params.row, params.rowIndex)
                              context.hideConfirm()
                          }
                      })
                  }}>删除</Button>
              </Grid>
            </Grid>
      }
    }
  ]

  return <Page
    className={classes.root}
    title="积分项"
  >
    <Container maxWidth={false}>
      <Grid container className={classes.toolbar} justify="space-between">
        <Grid item container >
          <Button variant="contained" color="primary" href="/app/scoreitem/add" >添加积分项</Button>
        </Grid>
      </Grid>

      {list && <DataGrid components={{
            footer: Pager
          }}
        disableSelectionOnClick={true}
        className={classes.table}
        autoHeight
        rows={list}
        columns={columns}
        pagination
        paginationMode="server"
        pageSize={50} 
        rowCount={rowCount}
        onPageChange={(params) => {
          setOffset((params.page - 1) * 50)
        }}
        loading={loading}
      ></DataGrid>
      }
    </Container>

  </Page>
}

export default List