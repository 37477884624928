import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Link,
  Tooltip
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import request from 'src/utils/request'
import { useNavigate } from 'react-router-dom';

import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light
  },
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/app/festival">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />

        <div>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <Link href="/app/changepwd" >修改密码</Link>
            </MenuItem>
            <MenuItem onClick={() => {
              request({
                url: '/ajax/logout',
                method: 'get',
                data: {}
              }).then(res => {
                navigate('/login', { replace: true });
              }, err => {

              })
            }}>登出</MenuItem>
          </Menu>
        </div>
        
        {/* <Hidden mdDown>
          <Tooltip title="退出登录">
            <IconButton onClick={() => {
              request({
                url: '/ajax/logout',
                method: 'get',
                data: {}
              }).then(res => {
                navigate('/login', { replace: true });
              }, err => {

              })
            }} color="secondary">
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden> */}
        <Hidden lgUp>
          <IconButton
            color="secondary"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
