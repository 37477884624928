import React, { useState, useEffect, useContext } from 'react'
import { 
    makeStyles,
    Container,
    Grid,
    TextField,
    InputAdornment,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core"
import Page from 'src/components/Page'
import { DataGrid } from '@material-ui/data-grid'
import request from 'src/utils/request'
import SearchIcon from '@material-ui/icons/Search';
import { Formik } from 'formik'
import * as Yup from 'yup'
import appContext from 'src/AppContext'
import Pager from 'src/utils/Pager'
import { dateFormat } from 'src/utils/utils'


const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      padding: theme.spacing(3)
    },
    table: {
      backgroundColor: '#ffffff'
    },
    toolbar: {
      marginBottom: 12
    },
    select: {
      width: 100,
      '&::before' : {
        display: 'none'
      }
    }
  }));

const List = () => {
    const classes = useStyles()
    const context = useContext(appContext)

    const searchSetting = [
      {
        label: 'ID搜索',
        key: 'id',
        xs: 3
      }, {
        label: '用户名搜索',
        key: 'username',
        xs: 3
      }, {
        label: '手机号搜索',
        key: 'mobile',
        xs: 3
      }
    ]

    const [list, setList] = useState()
    const [reload, setReload] = useState(false)
    const [rowCount, setRowCount] = useState(0)
    const [offset, setOffset] = useState(0)
    const [loading, setLoading] = useState(false)
    const [keyword, setKeyword] = useState({})


    const updateKeyword = (key, value) => {
      let copy = Object.assign({}, keyword)
      copy[key] = value
      setKeyword(copy)
    }

    const goReload = () => {
      setReload(false)
      setTimeout(() => {
        setReload(true)
      })
    }

    useEffect(() => {
      setLoading(true)
      request({
        url: '/ajax/user/list',
        method: 'get',
        data: {
          limit: 20,
          offset,
          username: keyword.username || '',
          id: keyword.id || '',
          mobile: keyword.mobile || ''
        }
      }).then(res => {

        if (offset === 0) {
          goReload()
        }

        if (res.data && res.data.list) {
          setList(res.data.list.map((item, index) => {
            item.uid = item.id
            item.id = index
            return item
          }))
          setRowCount(res.data.total)
        }
        setLoading(false)
      })

    }, [offset, keyword])
    
    return <Page
        className={classes.root}
        title="用户管理"
    >
      <Container maxWidth={false}>
        <Grid container direction="column" spacing={2} className={classes.toolbar}>
          <Grid item container direction="row" spacing={1}>
            {searchSetting.map(item => {
              return <Grid item xs={item.xs} >
                <TextField
                  label={item.label}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  placeholder="输入后按回车"
                  onKeyUp={(e) => {
                    let value = e.target.value

                    if (e.key === 'Enter') {
                      if  (item.key === 'id')  {
                        if (!value.match(/^\d*$/)) {
                          context.showToast('请输入数字')
                          return
                        }
                      }

                      updateKeyword(item.key, value)
                      setOffset(0)
                    }

                    if (value === '') {
                      updateKeyword(item.key, '')
                      setOffset(0)
                    }
                  }}
                ></TextField>
              </Grid>
            })}
            
          </Grid>
          <Grid item>{reload && <DataGrid
            components={{
              footer: Pager
            }}
            disableSelectionOnClick={true}
            className={classes.table}
            autoHeight
            rowHeight={80}
            rows={list}
            columns={[
              {
                field: 'uid',
                headerName: 'id',
                width: 110
              },
              {
                field: 'username',
                headerName: '用户名',
                width: 180,
              },
              {
                field: 'avatar',
                headerName: '头像',
                width: 72,
                renderCell: (params) => {
                  if(!params.value){
                    return ""
                  }
                  return <div>
                    <img style={{width: 40, height: 40, marginTop: 20}} src={params.row.avatar} />
                    </div>
                }
              },
              {
                field: 'gender',
                headerName: '性别',
                width: 72,
                valueGetter: (params) => {
                  switch (params.value) {
                    case 0:
                      return '未知'
                    case 1:
                      return '男'
                    case 2:
                      return '女'
                  }
                  return '未知'
                }
              },
              {
                field: 'ctime',
                headerName: '注册时间',
                width: 170,
                sortable: false,
                valueGetter: (params) => {
                    return dateFormat(new Date(params.value * 1000)) 
                }
              },
              {
                field: 'mobile',
                headerName: '手机号',
                width: 150,
              },
              {
                field: 'score',
                headerName: '积分',
                width: 84,
              },
              {
                field: 'action',
                headerName: '操作',
                width: 200,
                renderCell: (params) => {
                  return <Grid container direction="row" spacing={1}>
                    <Grid item>
                        <Button 
                          size="small" 
                          color="default" 
                          variant="outlined" 
                          target="_blank"
                          href={`/app/order/0/0/${params.row.uid}`}>查看订单</Button>
                    </Grid>
                    <Grid item>
                        <Button 
                          size="small" 
                          color="default" 
                          variant="outlined" 
                          target="_blank"
                          href={`/app/score/${params.row.uid}`}>积分明细</Button>
                    </Grid>
                  </Grid>
                }
              },

            ]}
            pagination
            paginationMode="server"
            pageSize={20} 
            rowCount={rowCount}
            onPageChange={(params) => {
              setOffset((params.page - 1) * 20)
            }}
            rowsPerPageOptions={[]}
            loading={loading}
          ></DataGrid>}</Grid>
        </Grid>
      </Container>
      
    </Page>
}

export default List