import React, { useState, useContext, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Container,
  makeStyles,
  Grid,
  TextField,
  Button,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Checkbox,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Page from 'src/components/Page'
import appContext from 'src/AppContext'
import { useParams, useNavigate } from "react-router-dom"
import UploadImageNormal from 'src/utils/uploadImageNormal'
import UploadImage from 'src/utils/uploadImage'
import request from 'src/utils/request'
import LinkInput from 'src/components/LinkInput'
import {dateFormat} from 'src/utils/utils'


const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  Container: {
    backgroundColor: theme.palette.background.default,
    padding: 20
  },
  pageTitle: {
    paddingBottom: 20
  },
  form: {
    paddingTop: 20
  }
}))

let formList = [
    {
        key: 'name',
        text: '名称',
        type: 'text',
        defaultValue: ''
    },
    {
        key: 'type',
        text: '类型',
        type: 'radio',
        defaultValue: "1",
        options: [{
            key: "1",
            text: '增加'
        }, {
            key: "2",
            text: '兑换'
        }]
    },
    {
        key: 'amount',
        text: '分数',
        type: 'text',
        defaultValue: 0,
    },
    {
        key: 'begin_time_str',
        text: '开始日期',
        type: 'date',
        defaultValue: dateFormat(new Date(), 'YYYY-mm-dd')
    },
    {
        key: 'end_time_str',
        text: '结束日期',
        type: 'date',
        defaultValue: dateFormat(new Date(), 'YYYY-mm-dd')
    },
]

const Edit = () => {
    const classes = useStyles()
    const context = useContext(appContext)
    const navigate = useNavigate();

    let { id } = useParams()
    let pageTitle = id !== undefined ? '编辑积分项' : '添加积分项'

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (id) {
            request({
                url: '/ajax/scoreitem/info',
                method: 'get',
                data: {
                  id: id
                }
            }).then(res => {
                initData(res.data)
                setLoading(false)
            })
            setLoading(false)
        } else {
            setDefaultParams()
            setLoading(false)
        }
    }, [])

    const initData = (data) => {
        for (let key in data) {
          if (key === 'begin_time') {
            data['begin_time_str'] = dateFormat(new Date(data[key] * 1000), 'YYYY-mm-dd')
          }else if (key === 'end_time') {
            data['end_time_str'] = dateFormat(new Date(data[key] * 1000), 'YYYY-mm-dd')
          }
        }
        setData(data)
      }

    const setDefaultParams = () => {
        // 表单不填却需要上报的参数，或者特殊定制的输入，都可以放在这里
        let d = {
            id: 0
        }
        formList.forEach(item => {
            d[item.key] = item.defaultValue
        })
        setData(d)
    }
    
    const beforeSendData = (data) => {
        let copy = Object.assign({}, data)
        for (let key in copy) {
            if (key === 'begin_time_str') {
                copy['begin_time'] = Math.floor(new Date(data.begin_time_str)/1000)
            }

            if (key === 'end_time_str') {
                copy['end_time'] = Math.floor(new Date(data.end_time_str)/1000)
            }
        }
        return copy
      }

    return <Page className={classes.root} title={pageTitle}>
        <Container maxWidth={false} className={classes.Container}>
            <h2 className={classes.pageTitle}>{pageTitle}</h2>
            <hr/>
            {!loading && data && <Formik
                initialValues={data}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required('请输入名称').max(50, '标题不能超过40个字'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    // console.log(values)
                    let data = beforeSendData(values)
                    request({
                        url: '/ajax/scoreitem/save',
                        data: data,
                      }).then(res => {
                        setSubmitting(false)
                        context.showToast('保存成功')
                        setTimeout(() => {
                            navigate('/app/scoreitem', { replace: true });
                          }, 500)
                      }, err => {
                        setSubmitting(false)
                        context.showToast(err.message)
                      })
                    
                }}>
                {
                    ({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setFieldError,
                        touched,
                        values
                    }) => (
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" spacing={2}>
                                {formList.map(item => (
                                    <>{item.type === 'text' && <Grid container item xs={4}>
                                        <TextField
                                            fullWidth
                                            label={item.text} 
                                            name={item.key}
                                            value={values[item.key]}
                                            multiline={item.rows? true : false}
                                            rows={item.rows}
                                            placeholder={item.placeholder}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched[item.key] && errors[item.key])}
                                            helperText={touched[item.key] && errors[item.key]}/>
                                        </Grid>}
                                        {item.type === 'link' && <Grid container item xs={4} spacing={1} direction="column">
                                            <Grid item>
                                                <InputLabel shrink>{item.text}</InputLabel>
                                            </Grid>
                                            <Grid item>
                                                <LinkInput defaultValue={item.defaultValue} onChange={(v) => {
                                                    setFieldValue(item.key, v)
                                                    setFieldError(item.key, null)
                                                }}></LinkInput>
                                                {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                            </Grid>
                                        </Grid>}
                                        {(item.type === 'image') && <Grid container item xs={4} direction="column">
                                            <InputLabel shrink required={item.require || false}>{item.text}</InputLabel>
                                            <UploadImage 
                                                width={item.width || 100} 
                                                height={item.height || 100} 
                                                prefix={item.prefix || ""}
                                                text={`上传${item.text}`}
                                                image={values[item.key]}
                                                type={item.type}
                                                onChange={(val) => {
                                                    setFieldValue(item.key, val)
                                                }}
                                                error={Boolean(touched[item.key] && errors[item.key])}></UploadImage>
                                        </Grid>}
                                        {item.type === 'radio' && <Grid container item xs={8} direction="column">
                                            <InputLabel shrink>{item.text}</InputLabel>
                                            <RadioGroup 
                                                name={item.key} 
                                                row 
                                                value={values[item.key]}
                                                onChange={handleChange}>
                                                {item.options.length > 0 && item.options.map(op => {
                                                return <FormControlLabel value={op.key} control={<Radio />} label={op.text} />
                                                })}
                                            </RadioGroup>
                                            {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                        </Grid>}
                                        {item.type === 'select' && <Grid container item xs={2} direction="column">
                                            <FormControl style={{width: '100%'}}>
                                                <InputLabel shrink>{item.text}</InputLabel>
                                                <Select
                                                    defaultValue={item.defaultValue}
                                                    onChange={handleChange}>
                                                    {item.options.map(op => (
                                                        <MenuItem value={op.key}>{op.text}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                        </Grid>}
                                        {item.type === 'checkbox' && <Grid container item xs={8} direction="column">
                                            <InputLabel shrink>{item.text}</InputLabel>
                                            <Grid container item>
                                                {item.options.length > 0 && item.options.map(op => {
                                                    return <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                        name={op.key}
                                                        color="primary"
                                                        defaultChecked={op.checked}/>
                                                    }
                                                    label={op.text}
                                                    onChange={(e) => {
                                                        let arr = values[item.key].split(',')
                                                        let key = e.target.name
                                                        let checked = e.target.checked
                                                        if (checked) {
                                                            arr.push(key)
                                                        } else {
                                                            arr.splice(arr.indexOf(key), 1)
                                                        }
                                                        setFieldValue(item.key, arr.join(','))
                                                        setFieldError(item.key)
                                                    }}/>
                                                })}
                                                {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                            </Grid>
                                        </Grid>}
                                        {item.type === 'date' && <Grid container item xs={3}>
                                        <TextField
                                            fullWidth
                                            type="date"
                                            label={item.text} 
                                            InputLabelProps={{
                                                shrink: true,
                                              }}
                                            name={item.key}
                                            value={values[item.key]}
                                            placeholder={item.placeholder}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched[item.key] && errors[item.key])}
                                            helperText={touched[item.key] && errors[item.key]}/>
                                        </Grid>}
                                        {item.type === 'datetime' && <Grid container item xs={4}>
                                        <TextField
                                            fullWidth
                                            type={"datetime-local"}
                                            label={item.text} 
                                            InputLabelProps={{
                                                shrink: true,
                                              }}
                                            name={item.key}
                                            value={values[item.key]}
                                            multiline={item.rows? true : false}
                                            rows={item.rows}
                                            placeholder={item.placeholder}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched[item.key] && errors[item.key])}
                                            helperText={touched[item.key] && errors[item.key]}/>
                                        </Grid>}
                                    </>
                                    
                                ))}

                                <Grid item xs={2}>
                                    <Button color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained">保存</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }
            </Formik>}
        </Container>
    </Page>
}

export default Edit