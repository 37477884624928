import React, { useEffect, useState,useContext } from 'react';
import {
  Container,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Page from 'src/components/Page';
import Pager from 'src/utils/Pager'
import request from 'src/utils/request'
import appContext from 'src/AppContext'

const Statact = ({
  ...rest
}) => {
  const context = useContext(appContext)

  const [list, setList] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(false)

  const [acts, setActs] = useState([])
  const [curaid, setCuraid] = useState(0)

  const fetchList = () => {
    if(!curaid || curaid == 0){
      setList([])
      return
    }

    setLoading(true)

    request({
      url: '/ajax/activity/stat_order',
      method: 'get',
      data: {
        limit: 50,
        offset: offset,
        activity_id: curaid
      }
    }).then(res => {
      setRowCount(res.data.length)
      setList(res.data.map((item, index) => {
          item.mid = item.id
          item.id = index
          return item
      }))
      setLoading(false)
    })
  }

  useEffect(() => {
      request({
          url: '/ajax/activity/list',
          method: 'get',
          data: {
              limit: 100,
          },
      }).then(res => {
          let actArr = []
          res.data.list.forEach((v, i, arr) => {
            actArr.push({text:v.title, key:v.id})
          })
          setActs(actArr)
      })
  }, [])

  useEffect(() => {
    if (curaid) {
        fetchList()
    }
}, [curaid])

  const columns = [
    {
      field: 'title',
      headerName: '活动',
      width: 180,
    }, 
    {
      field: 'order_sum_num',
      headerName: '报名数',
      width: 120,
    }, 
    {
      field: 'order_sum_use_num',
      headerName: '核销数',
      width: 120,
    }, 
    {
      field: 'order_sum_notuse_num',
      headerName: '未核销数',
      width: 120,
    }, 
  ]

  return <Page
    title="核销统计"
  >
    <Container maxWidth={false}>
    <Grid container style={{marginBottom: 30}} spacing={2}>
        <Grid container item xs={10}>
            <FormControl style={{width: '100%'}}>
                <InputLabel >活动</InputLabel>
                <Select
                    name="aid"
                    onChange={(e)=>{
                        // console.log(e.target.value)
                        setCuraid(e.target.value)
                    }}>
                    {acts.map(op => (
                        <MenuItem value={op.key}>{op.text}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
      </Grid>

      {list && <DataGrid components={{
            footer: Pager
          }}
        disableSelectionOnClick={true}
        autoHeight
        rows={list}
        columns={columns}
        pagination
        paginationMode="server"
        pageSize={50} 
        rowCount={rowCount}
        onPageChange={(params) => {
          setOffset((params.page - 1) * 50)
        }}
        loading={loading}
      ></DataGrid>
      }
    </Container>

  </Page>
}

export default Statact