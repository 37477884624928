import React, { useEffect, useState } from 'react'
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Container
} from '@material-ui/core'

const LinkInput = ({
    defaultValue,
    onChange,
    ...rest
}) => {

    const [type, setType] = useState('1') // 1h5，2小程序
    const [url, setUrl] = useState('')
    const [appid, setAppid] = useState('')
    const [path, setPath] = useState('')

    useEffect(() => {
        init()
    }, [defaultValue])


    const init = () => {
        if (defaultValue) {
            if (defaultValue.match(/^http/)) {
                setType('1')
                setUrl(defaultValue)
            } else {
                let arr = defaultValue.split('&')
                arr.forEach(item => {
                    let tempArr = item.split('=')
                    if (tempArr[0] === 'appid') {
                        setAppid(tempArr[1])
                    }
                    if (tempArr[0] === 'path') {
                        setPath(decodeURIComponent(tempArr[1]))
                    }
                })
                setType('2')
            }
        }
    }

    const getLink = () => {
        if (type === '1') {
            return url
        }

        if (type === '2') {
            return `appid=${appid}&path=${encodeURIComponent(path)}`
        }
    }


    useEffect(() => {
        onChange(getLink())
    }, [url, appid, path, type])

    return <Grid container direction="row" spacing={1}>
        <Grid item xs={2}>
            <FormControl style={{width: '100%'}}>
                <InputLabel>链接类型</InputLabel>
                <Select
                    value={type}
                    onChange={(e) => {
                        setType(e.target.value)
                    }}>
                    <MenuItem value={`1`}>H5</MenuItem>
                    <MenuItem value={`2`}>小程序</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        {type === '1' && <Grid item xs={10}>
            <TextField
                label="链接" fullWidth value={url}
                onChange={(e) => {
                    setUrl(e.target.value)
                }}></TextField>
        </Grid>}
        {type === '2' && <>
            <Grid item xs={5}>
                <TextField
                    label="APPID" fullWidth value={appid}
                    onChange={(e) => {
                        setAppid(e.target.value)
                    }}></TextField>
            </Grid>
            <Grid item xs={5}>
            <TextField
                label="PATH" fullWidth value={path}
                onChange={(e) => {
                    setPath(e.target.value)
                }}></TextField>
            </Grid>
        </>}
    </Grid>
    
}

export default LinkInput

