import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import {
  Container,
  makeStyles,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Page from 'src/components/Page';
import Pager from 'src/utils/Pager'
import request from 'src/utils/request'
import { dateFormat } from 'src/utils/utils'
import appContext from 'src/AppContext'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff',
  },
  toolbar: {
    marginBottom: 12
  },
  
}));

const List = () => {
  const classes = useStyles();

  let {fid} = useParams()

  const [rowCount, setRowCount] = useState(0)
  const [list, setList] = useState([])
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(false)
  const [qFid, setqFid] = useState(fid || 0)

  const [qfest, setQfest] = useState(null)
  const [festivals, setFestivals] = useState([])

  const context = useContext(appContext)

  useEffect(() => {
    request({
        url: '/ajax/festival/list',
        method: 'get',
        data: {
            limit: 100,
        },
    }).then(res => {
        let festArr = []
        res.data.list.forEach((v, i, arr) => {
            festArr.push({title:v.title, id:v.id})
        })
        setFestivals(festArr)
    })

    fetchList()
  }, [offset])

  useEffect(() => {
    if(fid && fid > 0){
      festivals && festivals.forEach((v, i, arr) => {
            if(fid == v.id){
              setQfest(v)
            }
        })
    }
  }, [fid, festivals])

  const fetchList = () => {
    setLoading(true)

    request({
      url: '/ajax/exhibition/list',
      method: 'get',
      data: {
        limit: 50,
        offset: offset,
        fid: (qFid && qFid != 0) ? qFid : '',
      }
    }).then(res => {
      setRowCount(res.data.total)
      setList(res.data.list.map((item, index) => {
          item.mid = item.id
          item.id = index
          return item
      }))
      setLoading(false)
    })
  }

  const updateState = (row, index) => {
    let newState = row.state === 1 ? 0 : 1
    request({
      url: '/ajax/exhibition/set_state',
      method: 'post',
      data: {
        id: row.mid,
        state: newState
      }
    }).then(res => {
      let newRows = list.concat()
      newRows[index].state = newState
      setList(newRows)
      context.showToast('操作成功')
    })
  }

  const remove = (row, index) => {
    request({
      url: '/ajax/exhibition/del',
      method: 'post',
      data: {
        id: row.mid
      }
    }).then(() => {
      let newRows = list.filter(function(value, index, arr){ 
          return row.mid != value.mid;
      });

      setList(newRows)
      context.showToast('删除成功')
    })
  }

  const updateSort = (row, value, index) => {
    request({
      url: '/ajax/exhibition/sort',
      method: 'post',
      data: {
        id: row.mid,
        sort: value
      }
    }).then(res => {
      let newRows = list.concat()
      newRows[index].sort = value
      setList(newRows)
    })
  }

  const columns = [
    {
      field: 'mid',
      headerName: 'ID',
      width: 80,
    }, 
    {
      field: 'fest_title',
      headerName: '设计护照',
      width: 200,
    }, 
    {
      field: 'board_name',
      headerName: '版块',
      width: 160,
      renderCell: (params) =>  (
        <Tooltip title={params.value} placement="bottom-start">
         <span className={classes.tableCellTrucate}>{params.value}</span>
         </Tooltip>
      ),
    }, 
    {
      field: 'title',
      headerName: '标题',
      width: 180,
      renderCell: (params) =>  (
        <Tooltip title={params.value} placement="bottom-start">
         <span className={classes.tableCellTrucate}>{params.value}</span>
         </Tooltip>
      ),
    }, 
    {
      field: 'cover_url',
      headerName: '封图',
      width: 120,
      sortable: false,
      renderCell: (params) => {
        if(params.value){
          return <img src={`${params.value}?imageView2/1/w/100`} style={{width: 50}} />
        }
      }
    }, 
    {
      field: 'begin_time',
      headerName: '开始时间',
      width: 150,
      sortable: false,
      valueGetter: (params) => {
          return dateFormat(new Date(params.value * 1000), "YYYY-mm-dd HH:MM") 
      }
    },
    {
      field: 'end_time',
      headerName: '结束时间',
      width: 150,
      sortable: false,
      valueGetter: (params) => {
          return dateFormat(new Date(params.value * 1000), "YYYY-mm-dd HH:MM") 
      }
    },
    {
      field: 'sort',
      headerName: '排序',
      width: 120,
      sortable: false,
      renderCell: (params) => {
        return  <>
        {!loading && <TextField
          className={classes.numberTextField}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: 0,
            max: 999
          }}
          variant="outlined"
          defaultValue={params.row.sort}
          onKeyUp={(e) => {
            if (parseInt(e.target.value) < 0) {
              e.target.value = 0
            }

            if (parseInt(e.target.value) > 999) {
              e.target.value = 999
            }
          }}
          onChange={(e) => {
            let sort = parseInt(e.target.value)
            if (sort >= 0 && sort <= 999) {
              updateSort(params.row, e.target.value, params.rowIndex)
            }
          }}
        />}
        </>
      }
    },
    {
      field: 'state',
      headerName: '状态',
      width: 120,
      align: 'center',
      sortable: false,
      valueGetter: (params) => {
          switch(params.value) {
          case 0: 
              return '草稿'
          case 1: 
              return '线上'
          default: 
              return ''
          }
      }
    },
    {
      field: 'action',
      headerName: '操作',
      width: 240,
      renderCell: (params) => {
        return <Grid container direction="row" spacing={1}>
              <Grid item>
                <Button 
                  size="small" 
                  variant="contained" 
                  color="primary"
                  href={`/app/exhibition/edit/${params.row.mid}`}
                  >编辑</Button>
              </Grid>
              {params.row.state === 0 && <Grid item>
                  <Button size="small" color="primary" variant="contained" onClick={() => {
                          updateState(params.row, params.rowIndex)
                      }}>上线</Button>
              </Grid>}
              {params.row.state === 1 && <Grid item>
                  <Button size="small" variant="outlined" onClick={() => {
                          updateState(params.row, params.rowIndex)
                      }}>下线</Button>
              </Grid>}
              <Grid item>
                  <Button size="small" color="default" variant="outlined" onClick={() => {
                      context.showConfirm({
                          text: '确定要删除吗？',
                          success: () => {
                              remove(params.row, params.rowIndex)
                              context.hideConfirm()
                          }
                      })
                  }}>删除</Button>
              </Grid>
            </Grid>
      }
    }
  ]

  return <Page
    className={classes.root}
    title="展览"
  >
    <Container maxWidth={false}>
      <Grid container className={classes.toolbar} spacing={2}>
        <Grid container item xs={4}>
          <Autocomplete id="ch-fest"
            disableClearable
            options={festivals}
            value={qfest}
            onChange={(event, newValue, reason) => {
                //console.log(newValue, reason)
                setQfest(newValue)
                setqFid(newValue.id)
              }}
            getOptionLabel={(option) => option.title}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} name="fest" label="设计护照" />}
            />
        </Grid>
        <Grid container item xs={2}>
          <Button color="primary" variant="outlined" fullWidth onClick={() => {
            fetchList()
          }}>查询</Button>
        </Grid>
        <Grid container item xs={2}>
          <Button color="default" variant="outlined" fullWidth onClick={() => {
              window.location.href = `/app/exhibition`
          }}>重置</Button>
        </Grid>
      </Grid>

      <Grid container className={classes.toolbar} justify="space-between">
        
        <Grid item container >
          <Button variant="contained" color="primary" href={`/app/exhibition/add/${qFid}`} >添加展览</Button>
        </Grid>
      </Grid>

      {list && <DataGrid components={{
            footer: Pager
          }}
        disableSelectionOnClick={true}
        className={classes.table}
        autoHeight
        rows={list}
        columns={columns}
        pagination
        paginationMode="server"
        pageSize={50} 
        rowCount={rowCount}
        onPageChange={(params) => {
          setOffset((params.page - 1) * 50)
        }}
        loading={loading}
      ></DataGrid>
      }
    </Container>

  </Page>
}

export default List