// 10秒超时
const TIMEOUT = 600000

function JSON2FormData (data) {
  return data? Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&') : ''
}

export default function request(options) {
  return Promise.race([
    new Promise((resolve, reject) => {
      let param = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      param.method = options.method || 'post'
      
      if (param.method === 'get') {
        if (options.data) {
          options.url += `?${JSON2FormData(options.data)}`
        }
      } else {
        param.body = JSON2FormData(options.data)
      }

      fetch(options.url, param).then(
        res => res.json(),
        err => reject(err)
      ).then(json => {
        if (json && json.status === 200 && json.code === 0) {
          resolve(json)
        } else {
          if (json && json.code === 100401) {
            // 未登录/登录状态失效
            window.location.href = '/login'
            reject(json)
          } else {
            reject(json)
          }
        }
      }, err => {
        reject(err)
      })
    }),
    new Promise((_, reject) =>
      setTimeout(() => reject({ code: -1 }), TIMEOUT)
    )
  ])
}