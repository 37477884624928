import React, {useEffect, useState, useContext} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  makeStyles,
  TextField,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  Link,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useParams, useNavigate } from "react-router-dom";
import UploadImage from 'src/utils/uploadImage'
import request from 'src/utils/request'
import appContext from 'src/AppContext'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  Container: {
    backgroundColor: theme.palette.background.default,
    padding: 20
  },
  pageTitle: {
    paddingBottom: 20
  },
  form: {
    paddingTop: 20
  },
  formControl:{
    minWidth: 120,
  }
}));

const formatTime = (d) => {
  let month = d.getMonth() + 1
  let date = d.getDate()
  month = month > 9 ? month : `0${month}`
  date = date > 9 ? date : `0${date}`

  return `${d.getFullYear()}-${month}-${date}`
}


const getTimeStr = (timeInt) => {
  if(timeInt <= 0){
    return ''
  }
  return formatTime(new Date(timeInt*1000))
}

const AdEdit = () => {
  const classes = useStyles();
  const context = useContext(appContext)
  const navigate = useNavigate();

  let { id } = useParams();
  let pageTitle = id !== undefined ? '编辑轮播图' : '添加轮播图'
  const [data, setData] = useState({
      "id": id || 0,
      // "title": "",
      "cover": "", //封图，七牛文件key
      "note": "", //说明
      "url": "", 
      // "jump_appid": "", 
      "jump_path": "", 
      // "begin_time": 0, 
      // "end_time": 0,
      // "begin_time_format": "",
      // "end_time_format": "",
      "state": 0,
    })

  const [loading, setLoading] = useState(true)
  const [linktype, setLinkType] = useState('0') //链接类型 0:h5, 1:小程序


  useEffect(() => {
      if (id) {
        request({
          url: '/ajax/banner/info',
          method: 'get',
          data: {
            id
          }
        }).then(res => {
          // res.data.begin_time_format = getTimeStr(res.data.begin_time)
          // res.data.end_time_format = getTimeStr(res.data.end_time)
          setData(res.data)
          if(res.data.url != ""){
            setLinkType("0")
          }else{
            if(res.data.jump_path != ""){
              setLinkType("1")
            }
          }
          
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    }, [id]) 

  return <Page className={classes.root} title={pageTitle} >
      <Container className={classes.Container} maxWidth={false}>
        <h2 className={classes.pageTitle}>{pageTitle}</h2>
        <hr/>

        {!loading && data && <Formik
          initialValues={data}
          validationSchema={Yup.object().shape({
            note: Yup.string().required('请输入说明'),
            // cover: Yup.string().required('请上传封图'),
            // url: linktype === '0' ? Yup.string().required('请填写链接') : Yup.string(),
            // jump_appid: linktype === '1' ? Yup.string().required('请填写appid') : Yup.string(),
            jump_path: linktype === '1' ? Yup.string().required('请填写path'): Yup.string()
          })}
          onSubmit={(values, { setSubmitting }) => {

            // if (!values.cover) {
            //   context.showToast('请上传封图')
            //   return
            // }

            if (linktype === '0') {
              // values.jump_appid = ''
              values.jump_path = ''
            } else {
              values.url = ''
            }

            // if (values.begin_time_format) {
            //   values.begin_time = Math.floor(new Date(values.begin_time_format).getTime()/1000)
            // }
            // if (values.end_time_format) {
            //   values.end_time = Math.floor(new Date(values.end_time_format).getTime()/1000)
            // }
            // console.log(values)
            request({
              url: '/ajax/banner/save',
              data: values
            }).then(res => {
              setSubmitting(false)
              context.showToast('保存成功')
              setTimeout(() => {
                if (!id) {
                  navigate('/app/banner', { replace: true });
                }
              }, 500)
            }, err => {
              setSubmitting(false)
              context.showToast(err.message)
            })
          }}>
          {
            ({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={2}>
                  <Grid container item xs={6}>
                    <TextField
                        fullWidth
                        label="说明" 
                        name="note"
                        value={values.note}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.note && errors.note)}
                        helperText={touched.note && errors.note}/>
                  </Grid>

                  <Grid container item xs={6} direction="column">
                    <InputLabel shrink>封图</InputLabel>
                    <UploadImage 
                      width={100} 
                      height={100} 
                      prefix="banner/pic/"
                      text={`上传图片`} 
                      image={values.cover}
                      onChange={(val) => {
                        values.cover = val
                        if (val) {
                          delete errors.cover
                        }
                      }}
                      error={Boolean(touched.cover && errors.cover)}
                      ></UploadImage>
                  </Grid>

                  <Grid container item spacing={2}>
                    <Grid item xs={2}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>链接类型</InputLabel>
                        <Select
                          defaultValue={(() => {
                            return linktype || '0'
                          })()}
                          onChange={(e) => {
                            setLinkType(e.target.value)
                          }}
                        >
                          <MenuItem value={`0`}>H5</MenuItem>
                          <MenuItem value={`1`}>小程序</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {linktype === '0' && <Grid item xs={4}>
                      <TextField
                        label="链接"
                        fullWidth
                        name="url"
                        value={values.url}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.url && errors.url)}
                        helperText={touched.url && errors.url}
                      />
                    </Grid>}

                    {/* {linktype === '1' && <Grid item xs={4}>
                      <TextField
                        label="APPID"
                        fullWidth
                        name="jump_appid"
                        value={values.jump_appid}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.jump_appid && errors.jump_appid)}
                        helperText={touched.jump_appid && errors.jump_appid}
                      />
                    </Grid>} */}
                    {linktype === '1' && <Grid item xs={4}>
                      <TextField
                        label="PATH"
                        fullWidth
                        name="jump_path"
                        value={values.jump_path}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.jump_path && errors.jump_path)}
                        helperText={touched.jump_path &&errors.jump_path}
                      />
                    </Grid>}
                  </Grid>

                  {/* <Grid container item xs={2} >
                    <InputLabel shrink>位置</InputLabel>
                    <TextField
                      type={"number"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="position"
                      value={values.position}
                      InputProps={{ inputProps: {min: 0} }}
                      onChange={handleChange}
                    />
                  </Grid> */}

                  {/* <Grid container item xs={2} >
                    <InputLabel shrink>开始时间</InputLabel>
                    <TextField
                      type={"date"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="begin_time_format"
                      value={values.begin_time_format}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid container item xs={2} >
                    <InputLabel shrink>结束时间</InputLabel>
                    <TextField
                      type={"date"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="end_time_format"
                      value={values.end_time_format}
                      onChange={handleChange}
                    />
                  </Grid> */}
                  
                  <Grid item xs={2}>
                    <Button color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained">保存</Button>
                  </Grid>
                </Grid>

              </form>
            )
          }
            
            
        </Formik>}
      </Container>
  </Page>
}

export default AdEdit