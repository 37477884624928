import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';
import {
  List as ListIcon,
  Edit as EditIcon,
  Users as UsersIcon,
  Image as ImageIcon,
  Gift as GiftIcon,
  Archive as ArchiveIcon,
  Activity as ActivityIcon,
  Columns as ColumnsIcon,
  CheckSquare as CheckSquareIcon,
} from 'react-feather';
import NavItem from './NavItem';

const items = [
  {
    href: '/app/banner',
    icon: ImageIcon,
    title: '首页'
  },
  {
    href: '/app/festival',
    icon: EditIcon,
    title: '设计护照'
  },
  {
    href: '/app/board',
    icon: ColumnsIcon,
    title: '版块'
  },
  {
    href: '/app/exhibition',
    icon: ArchiveIcon,
    title: '展览'
  },
  {
    href: '/app/activity',
    icon: ActivityIcon,
    title: '活动'
  },
  {
    href: '/app/order',
    icon: ListIcon,
    title: '订单'
  },
  {
    href: '/app/checkorder',
    icon: CheckSquareIcon,
    title: '验票'
  },
  {
    href: '/app/scoreitem',
    icon: GiftIcon,
    title: '积分项'
  },
  {
    href: '/app/user',
    icon: UsersIcon,
    title: '用户'
  },
  
];

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 132,
    top: 64,
    height: 'calc(100% - 64px)',
    backgroundColor: theme.palette.background.dark
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              items={item.items}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
