import React, { useState, useContext, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Container,
  makeStyles,
  Grid,
  TextField,
  Button,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Checkbox,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Page from 'src/components/Page'
import appContext from 'src/AppContext'
import { useParams, useNavigate } from "react-router-dom"
import UploadImage from 'src/utils/uploadImage'
import request from 'src/utils/request'
import QuillEditor from 'src/utils/quillEditor'
import LinkInput from 'src/components/LinkInput'
import {dateFormat} from 'src/utils/utils'


const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  Container: {
    backgroundColor: theme.palette.background.default,
    padding: 20
  },
  pageTitle: {
    paddingBottom: 20
  },
  form: {
    paddingTop: 20
  }
}))

let formList = [
    {
        key: 'title',
        text: '标题',
        type: 'text',
        defaultValue: ''
    },
    {
        key: 'cover',
        text: '封图',
        type: 'image',
        defaultValue: '',
        prefix: 'activity/',
        require: true,
    },
    {
        key: 'content',
        text: '内容',
        type: 'richtext',
        qiniuPrefix: 'activity/',
    },
    {
        key: 'begin_time_str',
        text: '开始日期',
        type: 'datetime',
        defaultValue: dateFormat(new Date(), 'YYYY-mm-ddTHH:MM')
    },
    {
        key: 'end_time_str',
        text: '结束日期',
        type: 'datetime',
        defaultValue: dateFormat(new Date(), 'YYYY-mm-ddTHH:MM')
    },
    {
        key: 'address',
        text: '地址',
        type: 'text',
        defaultValue: ''
    },
    {
        key: 'tip',
        text: '活动须知',
        type: 'text',
        rows: 5,
        defaultValue: ''
    }
]

const Edit = () => {
    const classes = useStyles()
    const context = useContext(appContext)
    const navigate = useNavigate();

    let { id, fid } = useParams()
    let pageTitle = id !== undefined ? '编辑活动' : '添加活动'

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const [boards, setBoards] = useState([])

    const [festivals, setFestivals] = useState([])
    const [curfid, setCurfid] = useState(0)

    useEffect(() => {
        request({
            url: '/ajax/festival/list',
            method: 'get',
            data: {
                limit: 100,
            },
        }).then(res => {
            let festArr = []
            res.data.list.forEach((v, i, arr) => {
                festArr.push({text:v.title, key:v.id})
            })
            setFestivals(festArr)
        })

        if (id) {
            request({
                url: '/ajax/activity/info',
                method: 'get',
                data: {
                  id: id
                }
            }).then(res => {
                initData(res.data)
                setLoading(false)
            })
            setLoading(false)
        } else {
            setDefaultParams()
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if(fid){
            setCurfid(fid)
        }

        if (data && data.fid>0) {
            setCurfid(data.fid)
        }
    }, [festivals, data])

    useEffect(() => {
        if (curfid) {
            fetchBoards(curfid)
        }
    }, [curfid])

    const fetchBoards = (fid) =>{
        request({
            url: '/ajax/board/list',
            method: 'get',
            data: {
                fid: fid,
                limit: 100,
            },
        }).then(res => {
            let boardArr = []
            res.data.list.forEach((v, i, arr) => {
                boardArr.push({text:v.name, key:v.id})
            })
            setBoards(boardArr)
        })
    }

    const initData = (data) => {
        for (let key in data) {
            if (key === 'begin_time') {
                data['begin_time_str'] = dateFormat(new Date(data[key] * 1000), 'YYYY-mm-ddTHH:MM')
            }else if (key === 'end_time') {
                data['end_time_str'] = dateFormat(new Date(data[key] * 1000), 'YYYY-mm-ddTHH:MM')
            }
        }
        setData(data)
      }

    const setDefaultParams = () => {
        // 表单不填却需要上报的参数，或者特殊定制的输入，都可以放在这里
        let d = {
            id: 0,
            fid: '',
            bid: '',
        }
        
        formList.forEach(item => {
            d[item.key] = item.defaultValue
        })
        if(fid > 0){
            d['fid'] = fid
        }
        setData(d)
    }
    
    const beforeSendData = (data) => {
        let copy = Object.assign({}, data)
        for (let key in copy) {
            if (key === 'begin_time_str') {
                copy['begin_time'] = Math.floor(new Date(data[key])/1000)
            }

            if (key === 'end_time_str') {
                copy['end_time'] = Math.floor(new Date(data[key])/1000)
            }
        }
        
        if(fid > 0){
            copy.fid = fid
        }
        
        return copy
      }


    return <Page className={classes.root} title={pageTitle}>
        <Container maxWidth={false} className={classes.Container}>
            <h2 className={classes.pageTitle}>{pageTitle}</h2>
            <hr/>
            {!loading && data && <Formik
                initialValues={data}
                validationSchema={Yup.object().shape({
                    title: Yup.string().required('请输入标题').max(50, '名称不能超过50个字'),
                    content: Yup.string().required('请填写内容'),
                    cover: Yup.string().required('请上传封图')
                })}
                onSubmit={(values, { setSubmitting }) => {
                    let data = beforeSendData(values)
                    
                    request({
                        url: '/ajax/activity/save',
                        data: data,
                      }).then(res => {
                        setSubmitting(false)
                        context.showToast('保存成功')
                        setTimeout(() => {
                            navigate('/app/activity/'+data.fid, { replace: true });
                          }, 500)
                      }, err => {
                        setSubmitting(false)
                        context.showToast(err.message)
                      })
                    
                }}>
                {
                    ({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setFieldError,
                        touched,
                        values
                    }) => (
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" spacing={2}>
                                <Grid container item xs={4} direction="column">
                                    <FormControl style={{width: '100%'}}>
                                        <InputLabel shrink required={true}>设计护照</InputLabel>
                                        <Select
                                            name="fid"
                                            value={values["fid"]}
                                            onBlur={handleBlur}
                                            onChange={(e)=>{
                                                setCurfid(e.target.value)
                                                handleChange(e)
                                            }}>
                                            {festivals.map(op => (
                                                <MenuItem value={op.key}>{op.text}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {touched["fid"] && errors["fid"] && <FormHelperText error>{errors["fid"]}</FormHelperText>}
                                </Grid>
                                <Grid container item xs={4} direction="column">
                                    <FormControl style={{width: '100%'}}>
                                        <InputLabel shrink>版块</InputLabel>
                                        <Select
                                            name="bid"
                                            value={values["bid"]}
                                            onChange={(e)=>{
                                                handleChange(e)
                                            }}>
                                            {boards.map(op => (
                                                <MenuItem value={op.key}>{op.text}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {touched["bid"] && errors["bid"] && <FormHelperText error>{errors["bid"]}</FormHelperText>}
                                </Grid>

                                {formList.map(item => (
                                    <>{item.type === 'text' && <Grid container item xs={6}>
                                        <TextField
                                            fullWidth
                                            label={item.text} 
                                            name={item.key}
                                            value={values[item.key]}
                                            multiline={item.rows? true : false}
                                            rows={item.rows}
                                            placeholder={item.placeholder}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched[item.key] && errors[item.key])}
                                            helperText={touched[item.key] && errors[item.key]}/>
                                        </Grid>}
                                        {item.type === 'richtext' && <Grid container item direction="column" >
                                            <Grid item>
                                            <InputLabel shrink>{item.text}</InputLabel>
                                            </Grid>
                                            <Grid item>
                                            <QuillEditor 
                                                html={values[item.key]}
                                                qiniuPrefix={item.qiniuPrefix} 
                                                onChange={(content, html) => {
                                                    values[item.key] = html
                                                    if (values[item.key]) {
                                                        delete errors[item.key]
                                                    }
                                                }}></QuillEditor>
                                                {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                            </Grid>
                                        </Grid>}
                                        {item.type === 'link' && <Grid container item xs={4} spacing={1} direction="column">
                                            <Grid item>
                                                <InputLabel shrink>{item.text}</InputLabel>
                                            </Grid>
                                            <Grid item>
                                                <LinkInput defaultValue={item.defaultValue} onChange={(v) => {
                                                    setFieldValue(item.key, v)
                                                    setFieldError(item.key, null)
                                                }}></LinkInput>
                                                {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                            </Grid>
                                        </Grid>}
                                        {(item.type === 'image' || item.type === 'audio') && <Grid container item xs={4} direction="column">
                                            <InputLabel shrink required={item.require}>{item.text}</InputLabel>
                                            <UploadImage 
                                                width={item.width || 100} 
                                                height={item.height || 100} 
                                                prefix={item.prefix || ""}
                                                text={`上传${item.text}`}
                                                image={values[item.key]}
                                                type={item.type}
                                                onChange={(val) => {
                                                    setFieldValue(item.key, val)
                                                }}
                                                error={Boolean(touched[item.key] && errors[item.key])}></UploadImage>
                                        </Grid>}
                                        {item.type === 'radio' && <Grid container item xs={8} direction="column">
                                            <InputLabel shrink>{item.text}</InputLabel>
                                            <RadioGroup 
                                                name={item.key} 
                                                row 
                                                value={values[item.key]}
                                                onChange={handleChange}>
                                                {item.options.length > 0 && item.options.map(op => {
                                                return <FormControlLabel value={op.key} control={<Radio />} label={op.text} />
                                                })}
                                            </RadioGroup>
                                            {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                        </Grid>}
                                        {item.type === 'select' && <Grid container item xs={2} direction="column">
                                            <FormControl style={{width: '100%'}}>
                                                <InputLabel shrink>{item.text}</InputLabel>
                                                <Select
                                                    defaultValue={item.defaultValue}
                                                    onChange={handleChange}>
                                                    {item.options.map(op => (
                                                        <MenuItem value={op.key}>{op.text}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                        </Grid>}
                                        {item.type === 'checkbox' && <Grid container item xs={8} direction="column">
                                            <InputLabel shrink>{item.text}</InputLabel>
                                            <Grid container item>
                                                {item.options.length > 0 && item.options.map(op => {
                                                    return <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                        name={op.key}
                                                        color="primary"
                                                        defaultChecked={op.checked}/>
                                                    }
                                                    label={op.text}
                                                    onChange={(e) => {
                                                        let arr = values[item.key].split(',')
                                                        let key = e.target.name
                                                        let checked = e.target.checked
                                                        if (checked) {
                                                            arr.push(key)
                                                        } else {
                                                            arr.splice(arr.indexOf(key), 1)
                                                        }
                                                        setFieldValue(item.key, arr.join(','))
                                                        setFieldError(item.key)
                                                    }}/>
                                                })}
                                                {touched[item.key] && errors[item.key] && <FormHelperText error>{errors[item.key]}</FormHelperText>}
                                            </Grid>
                                        </Grid>}
                                        {item.type === 'date' && <Grid container item xs={3}>
                                        <TextField
                                            fullWidth
                                            type="date"
                                            label={item.text} 
                                            name={item.key}
                                            value={values[item.key]}
                                            placeholder={item.placeholder}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched[item.key] && errors[item.key])}
                                            helperText={touched[item.key] && errors[item.key]}/>
                                        </Grid>}
                                        {item.type === 'datetime' && <Grid container item xs={4}>
                                        <TextField
                                            fullWidth
                                            type={"datetime-local"}
                                            label={item.text} 
                                            InputLabelProps={{
                                                shrink: true
                                              }}
                                            name={item.key}
                                            value={values[item.key]}
                                            multiline={item.rows? true : false}
                                            rows={item.rows}
                                            placeholder={item.placeholder}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(touched[item.key] && errors[item.key])}
                                            helperText={touched[item.key] && errors[item.key]}/>
                                        </Grid>}
                                        {item.key === 'time_range'&& <Grid container item xs={3}>
                                            <InputLabel shrink>上课时间</InputLabel>
                                            <Grid container row>
                                                <Grid item xs={5}>
                                                    <TextField
                                                        value={values['time_range_begin']}
                                                        name={'time_range_begin'}
                                                        placeholder={item.placeholder}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched['time_range_begin'] && errors['time_range_begin'])}
                                                        helperText={touched['time_range_begin'] && errors['time_range_begin']}
                                                     />
                                                </Grid>
                                                <Grid container item xs={2} justify="center">
                                                 <span>-</span>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField 
                                                        value={values['time_range_end']}
                                                        name={'time_range_end'}
                                                        placeholder={item.placeholder}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched['time_range_end'] && errors['time_range_end'])}
                                                        helperText={touched['time_range_end'] && errors['time_range_end']}
                                                        />
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <FormHelperText >{item.tips}</FormHelperText>
                                            </Grid>
                                        </Grid>}
                                    </>
                                    
                                ))}
                                <Grid item xs={2}>
                                    <Button color="primary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained">保存</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }
            </Formik>}
        </Container>
    </Page>
}

export default Edit