import React, { } from 'react';
import {
  Container,
  makeStyles,
  Grid,
  Link
} from '@material-ui/core';
import Page from 'src/components/Page'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff'
  },
  toolbar: {
    marginBottom: 12
  },
  select: {
    width: 100,
    '&::before' : {
      display: 'none'
    }
  },
  dialog: {
    width: 400
  },
}));

const Example = () => {
  const classes = useStyles();

  return <Page
    className={classes.root}
    title="编辑器"
  >
    <Container maxWidth={false}>
    </Container>
  </Page>
}

export default Example