import React, { useContext,useEffect,useState } from 'react';
import { useParams } from "react-router-dom"
import {
  Container,
  makeStyles,
  Grid,
  FormControl,
  TextField,
  Select,
  Button,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import appContext from 'src/AppContext'
import SearchIcon from '@material-ui/icons/Search';
import { DataGrid } from '@material-ui/data-grid';
import Pager from 'src/utils/Pager'
import request from 'src/utils/request'
import Statact from 'src/views/order/statactivity'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  table: {
    backgroundColor: '#ffffff'
  },
  toolbar: {
    marginBottom: 12
  },
  select: {
    '&::before' : {
      display: 'none'
    }
  },
  tabs: {
    marginBottom: 30
  },
}));

const Checkpage = () => {
  const classes = useStyles();
  const context = useContext(appContext)

  const [tab, setTab] = useState('tab1')

  const [offset, setOffset] = useState(0)
  const [rows, setRows] = useState([])
  const [qcontact, setQcontact] = useState("")

  const fetchOrders = () => {
    // setLoading(true)
    request({
      url: '/ajax/order/list',
      method: 'get',
      data: {
        limit: 50,
        offset: offset,
        contact: (qcontact) ? qcontact : '',
      }
    }).then(res => {
        // setRowCount(res.data.total)
        setRows(res.data.list.map((item, index) => {
            item.mid = item.id
            item.id = index
            return item
        }))
        // setLoading(false)
    })
  }

  const checkOrder = (oid) => {
    // console.log(oid)

    request({
      url: '/ajax/order/use',
      method: 'post',
      data: {
        oids: oid,
      }
    }).then(res => {
      // console.log(res.data)
      let newRows = rows.concat()
      newRows.map((item, index) => {
          if(item.mid == oid){
            item.use_state = 2
          }
          return item
      })
      setRows(newRows)
      context.showToast('操作成功')
    })
  }


  useEffect(() => {
    if (window.location.hash) {
      setTab(window.location.hash.replace('#', ''))
    } else {
      setTab('tab1')
    }
  }, [window.location.hash])

  return <Page
    className={classes.root}
    title="验票"
  >
    <Container maxWidth={false}>
        <Tabs 
            className={classes.tabs}
            value={tab} 
            onChange={(e, newValue) => {
                setTab(newValue)
                window.location.hash = newValue
            }}
            variant="fullWidth" 
            indicatorColor="primary">

            <Tab label="手动验票" value={'tab1'} />
            <Tab label="验票统计" value={'tab2'} />
        </Tabs>

        {tab === 'tab1' && <>
        <Grid container className={classes.toolbar} spacing={1}>
          <Grid item xs={8} >
            <TextField
              label="姓名或手机号"
              fullWidth
              variant="outlined"
              color="primary"
              defaultValue=""
              onChange={(e) => {
                setQcontact(e.target.value || "")
              }}
            ></TextField>
          </Grid>
          <Grid container item xs={2}>
            <Button color="primary" variant="outlined" fullWidth onClick={() => {
              if(qcontact == ""){
                setRows([])
                return
              }
              fetchOrders()
            }}>查询</Button>
          </Grid>
        </Grid>
          
        <Grid container >
          <Grid item container xs={12}>

          {rows.length > 0 && rows.map(item => (
            <Grid container spacing={1} style={{marginBottom:10}}>
              <Grid item xs={4}>
                <Typography>{item.contact_name}</Typography>
                <Typography variant="body2" style={{color:"#808080"}}>{item.contact_mobile}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>{item.activity_title}</Typography>
                <Typography variant="body2" style={{color:"#808080"}}>{`${item.product_title} x ${item.num}`}</Typography>
              </Grid>
              <Grid item xs={3}>
                {item.use_state ==1 && <Button color="primary" variant="contained" onClick={()=>{
                  checkOrder(item.mid)
                  }}>
                    验票</Button>}
                {item.use_state ==2 && <Typography variant="button" display="block" gutterBottom>
                已验票
                </Typography>}
                {item.use_state ==4 && <Typography variant="button" display="block" gutterBottom>
                已过期
                </Typography>}
              </Grid>
            </Grid>

              // <ListItem alignItems="flex-start">
              //   <ListItemText
              //     primary={item.contact_name}
              //     secondary={item.contact_mobile}
              //   />
              //   <ListItemText
              //     primary={item.activity_title}
              //     secondary={`${item.product_title} x ${item.num}`}
              //   />
              //   <ListItemSecondaryAction>
              //     {item.use_state ==1 && <Button color="primary" variant="contained" onClick={()=>{
              //       checkOrder(item.mid)
              //       }}>
              //         验票</Button>}
              //     {item.use_state ==2 && <Typography variant="button" display="block" gutterBottom>
              //     已验票
              //     </Typography>}
              //     {item.use_state ==4 && <Typography variant="button" display="block" gutterBottom>
              //     已过期
              //     </Typography>}
              //   </ListItemSecondaryAction>
              // </ListItem>
          ))}
          </Grid>
        </Grid>
        </>}

        {tab === 'tab2' && <Statact />}

    </Container>
  </Page>
}

export default Checkpage