import React, { useState, useEffect, useContext } from 'react'
import { 
    makeStyles,
    Container,
    Grid,
    TextField,
    Button
} from "@material-ui/core"
import { useNavigate } from "react-router-dom";
import Page from 'src/components/Page'
import request from 'src/utils/request'
import appContext from 'src/AppContext'
import { Formik } from 'formik'
import * as Yup from 'yup'

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      padding: theme.spacing(3)
    },
    toolbar: {
        marginBottom: 12
    },
  }));

const Settings = () => {
    const classes = useStyles()
    const [val, setVal] = useState('')
    const context = useContext(appContext)
    const navigate = useNavigate();

    return <Page
        className={classes.root}
        title="修改密码"
    >
        <Container maxWidth={false}>
          <Formik
            initialValues={{
              password: '',
              passwordconfirm: '',
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required('请输入密码')
                .min(6,"请输入6-18位").max(18,"请输入6-18位"),
              passwordconfirm: Yup.string().required('请再次输入密码')
                .oneOf([Yup.ref('password'), null], '两次输入不一致'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              // console.log(values)

              request({
                url: '/ajax/password',
                method: 'post',
                  data: {
                    password: values.password
                  }
              }).then(res => {
                setSubmitting(false)
                context.showToast('修改成功')
                setTimeout(() => {
                  navigate('/app/festival', { replace: true });
                }, 500)
              }, err => {
                setSubmitting(false)
                context.showToast(err.message)
              })
            }}>
            {
              ({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldError,
                  touched,
                  values
              }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid direction="column" container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="输入密码"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    error={Boolean(touched.passwordconfirm && errors.passwordconfirm)}
                    fullWidth
                    helperText={touched.passwordconfirm && errors.passwordconfirm}
                    label="再次输入密码"
                    margin="normal"
                    name="passwordconfirm"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.passwordconfirm}
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <Button color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained">确定</Button>
                </Grid>
            </Grid>
          </form>
                  )
              }
          </Formik>
        </Container>
    </Page>
}

export default Settings